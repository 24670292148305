export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

// Lifted from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
// For use creating unique keys.
export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getEnvironment = function () {
  return "https://api.landpride.com" + "/v1";
  //  return "https://" + "onprem-api-landpride.gpmfg.io" + "/v1";
  // switch (process.env.NODE_ENV) {
  //   case 'development':
  //     return "http://localhost:8000/v1";
  //     break;
  //   case 'production':
  //     return "https://" + "api.landpride.com" + "/v1";
  //     break;
  //   case 'staging':
  //     return "https://onprem-api-landpride.gpmfg.io" + "/v1";
  //     break;
  //   default:
  //     return "https://" + "api.landpride.com" + "/v1";
  //     break;
  // }
};
