import * as actionTypes from "./actionTypes";
import axios from "axios";
import { getEnvironment } from "./../../shared/utility";

export const fetchOrigin = () => {
  // console.log("TESTING");
  return (dispatch) => {
    dispatch(fetchOriginStart());
    const promises = [
      axios.get(`${getEnvironment()}/origin`),
      axios.get(`${getEnvironment()}/utility/nodeIndex`),
      axios.get(`${getEnvironment()}/landing-page`),
    ];

    const resolvedPromises = promises.map((promise) =>
      promise.catch((error) => ({ error }))
    );

    function checkFailed(then) {
      // console.log("TESTING here");
      return function (responses) {
        const someFailed = responses.some((response) => response.error);
        if (someFailed) {
          throw responses;
        }
        return then(responses);
      };
    }
    axios
      .all(resolvedPromises)
      .then(
        checkFailed(([origin, nodeIndex, landingPages]) => {
          // console.log(origin);
          let reverseSideBar = {};
          try {

            for (let landingPage in landingPages.data.landingPages) {
                landingPages.data.landingPages[landingPage].content_for_sidebar_menu != undefined &&  landingPages.data.landingPages[landingPage].content_for_sidebar_menu.target_nids.forEach((nid) => {
                if (!reverseSideBar[nid]) {
                  reverseSideBar[nid] = [];
                }
                reverseSideBar[nid].push(landingPage);
              });
            }
          } catch (e) {
            console.log("an error here ", e);
          }
          dispatch(
            fetchOriginSuccess({
              ...origin.data,
              ...nodeIndex.data,
              landingPages: { ...landingPages.data.landingPages },
              reverseSideBar: reverseSideBar,
            })
          );
        })
      )
      .catch((err) => {});
  };
};

export const fetchOriginStart = () => {
  return {
    type: actionTypes.FETCH_ORIGIN_START,
  };
};

export const fetchOriginSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_ORIGIN_SUCCESS,
  };
};

export const fetchOriginFail = (error) => {
  return {
    type: actionTypes.FETCH_ORIGIN_FAIL,
    error: error,
  };
};
