import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchUpcomingShows = () => {
  return (dispatch) => {
    dispatch(fetchUpcomingShowsStart());
    axios
      .get("/upcomingShows")
      .then((response) => {
        dispatch(fetchUpcomingShowsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUpcomingShowsFail(err));
      });
  };
};

/***********************************************************
// Fetch All UpcomingShows 
***********************************************************/
export const fetchUpcomingShowsStart = () => {
  return {
    type: actionTypes.FETCH_UPCOMING_SHOWS_START,
  };
};

export const fetchUpcomingShowsSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_UPCOMING_SHOWS_SUCCESS,
  };
};

export const fetchUpcomingShowsFail = (error) => {
  return {
    type: actionTypes.FETCH_UPCOMING_SHOWS_FAIL,
    error: error,
  };
};
