import React from "react";
import classes from "./Tertiary.module.scss";
import NavigationItems from "./NavigationItems/NavigationItems";

const tertiary = (props) => (
  <header className={`${classes.Tertiary} Tertiary`}>
    <div className={`${classes.DesktopOnly} DesktopOnly`}>
      <NavigationItems isAuthenticated={props.isAuth} />
    </div>
  </header>
);

export default tertiary;
