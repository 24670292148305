import React from "react";

function ConditionalWrapper(props) {
    return props.condition ? props.wrapper(props.children) : props.children;
}

export default ConditionalWrapper;

//This is the Comp you want to wrap around stuff.
// <ConditionalWrapper
//     condition={this.props.auth.auth}
//     wrapper={(children) => <div className={"HoverOver"}> <a href={`https://cms.landpride.com/en/node/${data.nid}/edit`}>EDIT {data.nid}</a>{children}</div>}
// >


