import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: true,
  searchString: "",
  searching: false,
};

const fetchManualsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchManualsSuccess = (state, action) => {
  return updateObject(state, {
   ...action.data,
    loading: false,
    error: false,
  });
};

const fetchManualClear = (state, action) => {
  return updateObject(state, {
    loading: true,
    searchString: "",
    searching: false,
  });
};


const fetchManualsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MANUALS_START:
      return fetchManualsStart(state, action);
    case actionTypes.FETCH_MANUALS_SUCCESS:
      return fetchManualsSuccess(state, action);
    case actionTypes.FETCH_MANUALS_FAIL:
      return fetchManualsFail(state, action);
    case actionTypes.FETCH_MANUALS_CLEAR:
      return fetchManualClear(state, action);
    default:
      return state;
  }
};

export default reducer;
