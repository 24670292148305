import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  country: "united-states",
  language: "en",
  loading: true,
  searchString: "",
};

const fetchOriginStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchOriginSuccess = (state, action) => {
  return updateObject(state, {
    ...action.data,
    loading: false,
    error: false,
  });
};

const fetchOriginFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORIGIN_START:
      return fetchOriginStart(state, action);
    case actionTypes.FETCH_ORIGIN_SUCCESS:
      return fetchOriginSuccess(state, action);
    case actionTypes.FETCH_ORIGIN_FAIL:
      return fetchOriginFail(state, action);
    default:
      return state;
  }
};

export default reducer;
