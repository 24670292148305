import React, {Component} from 'react';
import classes from "./../Home.module.scss"
import Link from "react-router-dom/Link";



    export default class DealerSearchBlock extends Component {
        constructor(props) {
            super(props);


            this.state = {
                city: "",
                zip: "",
                state: "",
                filter: "all",
            };
        }

        render() {

            let DealerLink = `/dealer-search/`

            if(this.state.zip){
                DealerLink = `/dealer-search-results/${this.state.zip}/${this.state.filter}`
            }
            if(this.state.city && this.state.state && this.state.filter ){
                DealerLink = `/dealer-search-results/${this.state.city}/${this.state.state}/${this.state.filter}`
            }



            return (
                <div className={classes.BlockWrap}>
                    {/*This is the Financing block*/}
                    <div className={`${classes.FinancingContent} Content`}>
                        <div className={classes.FinancingCaption}>
                            <h3 className={classes.FinancingHeader}>0% FINANCING</h3>
                            <div className={classes.Callout}>
                                Multiple financing options are available for purchasing Land Pride Implements
                            </div>
                            <Link to={"/8/financing"}>
                                <button className={classes.FindOutMoreButton}>Find Out More</button>
                            </Link>
                        </div>

                    </div>

                    {/*This is the DealerSearch block*/}
                    <div className={`${classes.Content} Content`}>
                        <div  className={classes.Locator}>
                        <div id="dealer-locator" className={classes.Caption}>
                            <h3 className={classes.DealerSearchHeader}>Find a Dealer</h3>


                            <form

                                id="dealerLocatorForm"
                                method="post"
                                name="dealerLocatorForm"
                                action=""
                            >
                                <div className={`${classes.FormWrapper} FormWrapper`}>
                                    <label htmlFor="Zip">
                                        Enter five-digit US ZIP code:
                                    </label>
                                    <p>
                                        <input
                                            id="Zip"
                                            name="Zip"
                                            type="text"
                                            placeholder="ZIP Code"
                                            onChange={(e) =>
                                                this.setState({zip: `${e.target.value}`})
                                            }
                                            defaultValue={this.state.zip}
                                            size="10"
                                            maxLength="7"
                                            className={"Zipcode"}
                                            key={"ZipKey"}
                                        />
                                    </p>
                                    <p>
                                        <strong>- OR -</strong>
                                    </p>
                                    <label htmlFor={"City"}>
                                        Enter your city and select your state:
                                    </label>
                                    <p>
                                        <input
                                            placeholder="Enter a City"
                                            name="City"
                                            type="text"
                                            id="City"
                                            onChange={(e) =>
                                                this.setState({city: `${e.target.value}`})
                                            }
                                            defaultValue={this.state.city}
                                            key={"CityKey"}
                                        />
                                        <select
                                            className={"State"}
                                            name="State"
                                            id="State"
                                            onChange={(e) =>
                                                this.setState({state: `${e.target.value}`})
                                            }
                                            defaultValue={this.state.state}
                                            key={"STATEKey"}
                                        >
                                            <option defaultValue="selected" value="">
                                                Choose Your State
                                            </option>
                                            <option value="AL">Alabama - (AL)</option>
                                            <option value="AK">Alaska - (AK)</option>
                                            <option value="AB">Alberta, CA - (AB)</option>
                                            <option value="AS">American Samoa - (AS)</option>
                                            <option value="AZ">Arizona - (AZ)</option>
                                            <option value="AR">Arkansas - (AR)</option>
                                            <option value="CA">California - (CA)</option>
                                            <option value="CO">Colorado - (CO)</option>
                                            <option value="CT">Connecticut - (CT)</option>
                                            <option value="DE">Delaware - (DE)</option>
                                            <option value="FM">
                                                Federated States Of Micronesia - (FM){" "}
                                            </option>
                                            <option value="FL">Florida - (FL)</option>
                                            <option value="GA">Georgia - (GA)</option>
                                            <option value="GU">Guam Gu - (GU)</option>
                                            <option value="HI">Hawaii - (HI)</option>
                                            <option value="ID">Idaho - (ID)</option>
                                            <option value="IL">Illinois - (IL)</option>
                                            <option value="IN">Indiana - (IN)</option>
                                            <option value="IA">Iowa - (IA)</option>
                                            <option value="KS">Kansas - (KS)</option>
                                            <option value="KY">Kentucky - (KY)</option>
                                            <option value="LA">Louisiana - (LA)</option>
                                            <option value="ME">Maine - (ME)</option>
                                            <option value="MD">Maryland - (MD)</option>
                                            <option value="MA">Massachusetts - (MA)</option>
                                            <option value="MI">Michigan - (MI)</option>
                                            <option value="MN">Minnesota - (MN)</option>
                                            <option value="MS">Mississippi - (MS)</option>
                                            <option value="MO">Missouri - (MO)</option>
                                            <option value="MT">Montana - (MT)</option>
                                            <option value="NE">Nebraska - (NE)</option>
                                            <option value="NV">Nevada - (NV)</option>
                                            <option value="NB">New Brunswick, CA - (NB)</option>
                                            <option value="NH">New Hampshire - (NH)</option>
                                            <option value="NJ">New Jersey - (NJ)</option>
                                            <option value="NM">New Mexico - (NM)</option>
                                            <option value="NY">New York - (NY)</option>
                                            <option value="NC">North Carolina - (NC)</option>
                                            <option value="ND">North Dakota - (ND)</option>
                                            <option value="OH">Ohio - (OH)</option>
                                            <option value="OK">Oklahoma - (OK)</option>
                                            <option value="ON">Ontario, CA - (ON)</option>
                                            <option value="OR">Oregon - (OR)</option>
                                            <option value="PW">Palau - (PW)</option>
                                            <option value="PA">Pennsylvania - (PA)</option>
                                            <option value="PR">Puerto Rico - (PR)</option>
                                            <option value="QC">Quebec, CA - (QC)</option>
                                            <option value="RI">Rhode Island - (RI)</option>
                                            <option value="SC">South Carolina - (SC)</option>
                                            <option value="SD">South Dakota - (SD)</option>
                                            <option value="TN">Tennessee - (TN)</option>
                                            <option value="TX">Texas - (TX)</option>
                                            <option value="UT">Utah - (UT)</option>
                                            <option value="VT">Vermont - (VT)</option>
                                            <option value="VI">Virgin Islands - (VI)</option>
                                            <option value="VA">Virginia - (VA)</option>
                                            <option value="WA">Washington - (WA)</option>
                                            <option value="WV">West Virginia - (WV)</option>
                                            <option value="WI">Wisconsin - (WI)</option>
                                            <option value="WY">Wyoming - (WY)</option>
                                        </select>
                                    </p>
                                    <label htmlFor={"filter"}>Select dealer type:</label>
                                    <p>
                                        <select
                                            className="DealerType"
                                            name="filter"
                                            id="filter"
                                            onChange={(e) =>
                                                this.setState({filter: `${e.target.value}`})
                                            }
                                            defaultValue={this.state.filter}
                                        >
                                            <option value="2"> Tractor Implements</option>
                                            <option value="12"> Compact Drills</option>
                                            <option value="ktc"> Kubuta Dealers</option>
                                        </select>
                                    </p>
                                </div>
                            </form>



                            <div className={`${classes.Buttons} Buttons`}>
                                <Link
                                    to={DealerLink}
                                >
                                    {" "}
                                    <button
                                        className={`${classes.Button} Button`}
                                        type="submit"
                                        value="Submit"
                                    >
                                        Search
                                    </button>
                                    {" "}
                                </Link>

                            </div>



                            <div className={classes.KubotaCallout}><a className={classes.KubotaCallout}  href="https://kubota.ca/en/dealer-finder" target="_blank">Kubota
                                Dealers in Canada</a></div>
                            <div className={classes.GrecaptchaDisclaimer}>
                                <p>
                                    By clicking ‘Submit’ you agree to reading and accepting the <a
                                    href="https://www.landpride.com/11072/great-plains-manufacturing-website-terms-of-use" target="_blank">Terms of
                                    Use</a> and <a href="https://www.landpride.com/5074/great-plains-and-land-pride-privacy-policy" target="_blank">Privacy
                                    Policy</a>.
                                </p>
                                This site is protected by reCAPTCHA, and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms"> Terms of Service </a> apply.

                            </div>
                            </div>
                        </div>

                    </div>

                </div>

            )
        }
    }
