/*
This function is used to trip body text down to summary size.
You pass it in a string and the max length you would like to trim it down to.
Author: Rob
Date: 5/4/2022
 */
export default function Truncate(string, length){

    //If the length is less than what we want to trim it to just return it
    if(length < string.length ) {
    var length = length + 25 //I added a little padding to the string so its maybe a little close to the desired length
        //Strip out HTMl so I dont have a <p> with no closing tag
    var stringStrip = string.replace(/<[^>]*>?/gm, '');

    //trim the string to the maximum length
    var trimmedString = stringStrip.substr(0, length);

    //Find the punctuation with the highest position in the string
    let period = stringStrip.substr(0, length).lastIndexOf(".")
    let questionmark = stringStrip.substr(0, length).lastIndexOf("?")
    let exclamation = stringStrip.substr(0, length).lastIndexOf("!")
        //If the substring is not in the string its -1 else the index
        //Math.max returns the highest number (e.g. 41, -1, -1) returns 41
    var max = Math.max(period, questionmark, exclamation);

    //re-trim if we are in the middle of a word
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, max + 1))
    //I added the +1 to add the punctuation

        return trimmedString + "..."

    } else return string


}
