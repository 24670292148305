import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    faqs: null,
    allFaqs: null,
    banners: null,
    PrimarySet: null,
    SecondarySet: null,
    loading: true,

};

const fetchFAQSStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
};

const fetchFAQSSuccess = (state, action) => {
    return updateObject(state, {
        faqs: action.data.faqs,
        allFaqs: action.data.allFaqs,
        banners: action.data.banners,
        PrimarySet: action.data.PrimarySet,
        SecondarySet: action.data.SecondarySet,
        loading: false,
    });
};

const fetchFAQSFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FAQS_START:
            return fetchFAQSStart(state, action);
        case actionTypes.FETCH_FAQS_SUCCESS:
            return fetchFAQSSuccess(state, action);
        case actionTypes.FETCH_FAQS_FAIL:
            return fetchFAQSFail(state, action);

        default:
            return state;
    }
};

export default reducer;
