import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

//Set the initial redux state for all products
const initialState = {
    products: null,
    families: null,
    loading: true,
    banners: null,
    filteringCount: 0,
    activeFilters: {},
    product:{},
    searchString:"",
    filterReset: null,
    searching: false,
    filters: {
        family: "all-products",
        product: null,
    },
}
//*******************Single NID*******************//

//When the fetch is fired off it starts the loader
const fetchLandProductStart = (state) => {
    return updateObject(state, {
        loading: true,
    })
};

//This is what gets set if success
const fetchLandProductSuccess = (state, action) => {

    return updateObject(state,{
        product: action.data.product,
        banners: action.data.banner,
        model: action.data.models,
        features_documents: action.data.features_documents,
        matches: action.data.matches,
        faq: action.data.FAQ,
        AdSlicks: action.data.AdSlicks,
        loading: false,
        error: false,
    })
}

//Set the fail state
const fetchLandProductFail = (state) => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

//*******************Single NID End*******************//



//*******************All products*******************//

//When the fetch is fired off it starts the loader
const fetchLandProductsStart = (state) => {
    return updateObject(state, {
        loading: true,
    })
};

//This is what gets set if success
const fetchLandProductsSuccess = (state, action) => {
    //Loop over the filters
    action.data.filters.forEach((filter) => {
        //Loop over the options inside of tilers
        let i = 0, len = filter.options.length;
        while (i < len) {
            //if the option name is the same as the family path make it checked [sets the checkbox]
            //Could also be option.tid and not name if we want
            if (filter.options[i].name == action.familyPath) {
                option.checked = true;
            }
            i++
        }

    })
    //End Loop over the filters

    //creating a filtered array
    let tempProducts = action.data.products.filter((product) => {
        let flag = true

        action.data.filters.forEach((filter) => {
            let i = 0, len = filter.options.length;
            while (i < len) {

                if(filter.options[i].checked) {
                    if (!product.subOptions.includes(filter.options[i].name.toString().toLowerCase().replaceAll(" ", "-"))) {
                        flag = false;
                    }
                    if(!product.subOptions.includes(filter.label.toString().toLowerCase().replaceAll(" ", "-"))){
                        flag = false;
                    }
                }
                i++
            }
        })

        return flag;
    })
    //End of filter array


    //This loop gets the count for us
    action.data.filters.forEach((filter) => {
        filter.options.forEach((option) => {

            option.count = 0;
            let i = 0, len = tempProducts.length;
            while (i < len) {

                //if the option name is in the sub options for the product add a count
                if (tempProducts[i].subOptions.includes(option.name.toString().toLowerCase().replaceAll(" ", "-"))) {
                    ++option.count;
                }
                i++
            }
        })

    });
    return updateObject(state,{
        products: action.data.products,
        banners: action.data.banners,
        families: action.data.families,
        filterReset: action.data.filters,
        loading: false,
        error: false,
    })
}


//*******************Resets the Search and the filters back to the start*******************//
const resetFlagsLandPride = (state) => {
    //Loop over the filters
    state.filterReset.forEach((filter) => {
        //set all the accordions to open or closed
        filter.showChildren = false;
        //loop over the options in the filter
        filter.options.forEach((option) =>{
            //set all the checked boxes to false and reset the count down to 0
            option.checked = false;
            option.count = 0;
            //loop over the product and if the product has a an option for the option name add 1 to the count
            //Count is used to show how many options we have in the UI like Red(27)
            let i = 0, len = state.products.length;
            while (i < len) {
                //if the option name is in the sub options for the product add a count
                if (state.products[i].subOptions.includes(option.name.toString().toLowerCase().replaceAll(" ", "-"))) {
                    ++option.count;
                }
                i++
            }


        })
    })

    return updateObject(state, {
        filtering: 0,
        filteringCount: 0,
        searchString: null,
        activeProducts: state.products,
        filters: state.filterReset,
    });

}


//*******************This dose that accordion action in the filters*******************//
const toggleShowChildrenLandPride = (state, action) => {
    let tempFilters = state.filterReset;
    Object.values(tempFilters).forEach((filter) => {
        //If the action(the box we clicked) is the same as the label from the api data loop over the options
        if (filter.label == action.section) {
            //Set Count back to 0
            let count = 0;
            let i = 0, len = filter.options.length;
            while (i < len) {
                //If its checked then start counting it
                if (filter.options[i].checked) {
                    ++count;
                }
                i++
            }
            //If it has no count then it gets removed from the UI. It narrows down the options for the user here
            if (!count) {
                filter.showChildren = !filter.showChildren;
            }
        }
    });
    return updateObject(state, {
        filters: tempFilters,
    });
};


//*******************This updates the filters after the box is check. It will also remove boxes if the count is 0*******************//
const filterLandChange = (state,action) => {



    Object.values(state.filters).forEach((category, index) => {

        //This part here will check the check box and change the filter count
        if(category.name == action.parent){
            category.options.forEach((option, i) => {
                //If the Option is the same as the child make the box check by turning the vale to true and then -1 or and to the filter count
                //Filter count is used in the products to decided what to show

                if(option.name == action.child){
                    state.filters[index].options[i].checked = !state.filters[index].options[i].checked
                    state.filteringCount = state.filters[index].options[i].checked ? state.filteringCount + 1 : state.filteringCount - 1;

                }
            })
        }
    })

    //This the the array we send back to the UI to map over. We filter out products that are not checked
    let tempProducts = state.products.filter((product) => {
        //Filter returns the item in the array based on a true or false value
        //So far everything passes and gets returned
        let flag = true
        //Loop over the categorizations
        state.filters.forEach((filter) =>{
            // console.log(product.subOptions)
            //Loop over each of the options for each of the categorization
            filter.options.forEach((option) =>{
                //If the option inside that categorizations is check
                if (option.checked) {
                    //Check if the option name is in the subOptions for this product of the categorizations and if not filter it out of this new array
                    if (!product.subOptions.includes(option.name.toString().toLowerCase().replaceAll(" ", "-"))) {
                        flag = false;
                    }
                }
            })
        })
        return flag;
    })

    //This is what removes different checkboxes from the UI on change
    state.filters.forEach((filter) => {
        filter.options.forEach((option) => {
            //Set all counts back to 0
                option.count = 0;
                let i = 0, len = tempProducts.length;
                while (i < len) {
                    //if the option name is in the sub options for the product add a count
                    if (tempProducts[i].subOptions.includes(option.name.toString().toLowerCase().replaceAll(" ", "-"))) {
                        ++option.count;
                    }
                    i++
                }
        });
    });

    return updateObject(state, {
        filtering: state.filteringCount,
        activeProducts: tempProducts,
        filters: state.filterReset,
    })

}

//Set the fail state
const fetchLandProductsFail = (state) => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

const fetchLandProductsSkipped = (state) => {
    return updateObject(state, {
        loading: false,
        error: false,
    });
};
//*******************End All products*******************//




//*******************Reducers*******************//
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LAND_PRODUCTS_START:
            return fetchLandProductsStart(state, action);
        case actionTypes.FETCH_LAND_PRODUCTS_SUCCESS:
            return fetchLandProductsSuccess(state, action);
        case actionTypes.FETCH_LAND_PRODUCTS_FAIL:
            return fetchLandProductsFail(state, action);
        case actionTypes.FILTER_LAND_CHANGE:
            return filterLandChange(state, action);
        case actionTypes.FETCH_LAND_PRODUCTS_SKIPPED:
            return fetchLandProductsSkipped(state, action);
        case actionTypes.RESET_FLAGS_LANDPRIDE:
            return resetFlagsLandPride(state, action);
        case actionTypes.TOGGLE_LAND_SHOW_CHILDREN:
            return toggleShowChildrenLandPride(state, action);
        //Single
        case actionTypes.FETCH_LAND_PRODUCT_START:
            return fetchLandProductStart(state, action);
        case actionTypes.FETCH_LAND_PRODUCT_SUCCESS:
            return fetchLandProductSuccess(state, action);
        case actionTypes.FETCH_LAND_PRODUCT_FAIL:
            return fetchLandProductFail(state, action);
        default:
            return state;
    }
};

//*******************Export*******************//
export default reducer;
