import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchManuals = () => {
  return (dispatch) => {
    dispatch(fetchManualsStart());
    axios
      .get("/manuals/")
      .then((response) => {dispatch(fetchManualsSuccess(response.data));})
      .catch((err) => {
        dispatch(fetchManualsFail(err));
      });
  };
};

export const clearManuals = () => {
  return (dispatch) => {
    dispatch(fetchManualClear());
  };
};

/***********************************************************
// Fetch All Manuals
***********************************************************/

export const fetchManualClear = () => {
  return {
    type: actionTypes.FETCH_MANUALS_CLEAR,
  };
};

export const fetchManualsStart = () => {
  return {
    type: actionTypes.FETCH_MANUALS_START,
  };
};

export const fetchManualsSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_MANUALS_SUCCESS,
  };
};

export const fetchManualsFail = (error) => {
  return {
    type: actionTypes.FETCH_MANUALS_FAIL,
    error: error,
  };
};
