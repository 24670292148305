import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    loading: true,
    searchString: "",
    searching: false,
};

const fetchADSStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const fetchADSSuccess = (state, action) => {

  //  Filter out products that have nothing in them so we dont get a checkbox and nothing in it
  let AdSlicks =  action.data.AdSlicks.filter(function (data){
      if(data[0].Products.length != 0){
          return true
      } else return  false
    })
    let Labels = action.data.Labels.filter(function (Label){
        let flag = false
        AdSlicks.forEach(function (Ad){
            if(Label.name == Ad[0].category){
                flag = true
            }
        })
     return flag
    })

    let newData = {AdSlicks,Labels}

    return updateObject(state, {
        ...newData,
        loading: false,
        error: false,
    });
};



const fetchADSFail = (state) => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ADS_START:
            return fetchADSStart(state, action);
        case actionTypes.FETCH_ADS_SUCCESS:
            return fetchADSSuccess(state, action);
        case actionTypes.FETCH_ADS_FAIL:
            return fetchADSFail(state, action);
        default:
            return state;
    }
};

export default reducer;
