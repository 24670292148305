import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchSearch = () => {
  return (dispatch) => {
    dispatch(fetchSearchStart());
    axios
      .get("/search/")
      .then((response) => {
        dispatch(fetchSearchSuccess(response.data));
      })
      .catch((err) => {});
  };
};

export const fetchSearchStart = () => {
  return {
    type: actionTypes.FETCH_SEARCH_START,
  };
};

export const fetchSearchSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_SEARCH_SUCCESS,
  };
};

export const fetchSearchFail = (error) => {
  return {
    type: actionTypes.FETCH_SEARCH_FAIL,
    error: error,
  };
};
