import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions";
import loadable from "@loadable/component";
import Layout from "./hoc/Layout/Layout";
import Home from "./containers/Home/Home";
import Spinner from "./components/UI/Spinner/Spinner";


const LandPrideProduct = loadable(() =>
    import("./containers/Products/Product/LandPrideProduct")
);
const LandprideProducts = loadable(() =>
    import("./containers/Products/LandPrideProducts")
);
const FAQ = loadable(() => import("./containers/FAQ/faq"));
const Node = loadable(() => import("./containers/Node/Node"));
const Manuals = loadable(() => import("./containers/Manuals/Manuals"));
const Article = loadable(() => import("./containers/Article/Article"));
const DealerSearch = loadable(() =>
    import("./containers/DealerSearch/DealerSearch")
);
const Search = loadable(() => import("./containers/Search/Search"));
const CompanyWebsites = loadable(() =>
    import("./components/CompanyWebsites/CompanyWebsites")
);
const Login = loadable(() => import("./components/Auth/Login/Login"));
const Logout = loadable(() => import("./components/Auth/Logout/Logout"));
const SerialNumberSearch = loadable(() =>
    import("./components/serialNumberSearch/serialNumberSearch")
);

const Partsmart = loadable(() =>
    import("./components/PartSmart/partsmartComponenet")
);


class App extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    this.props.onFetchNav();
    this.props.onFetchOrigin();
    const scriptRecaptcha = document.createElement("script");
    scriptRecaptcha.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_ReCaptchaV3_DealerSearch}`;
    scriptRecaptcha.async = true;
    document.body.appendChild(scriptRecaptcha);
  }



  render() {
    /*****************************************************************************************/
    /* DO NOT CHANGE THE ORDER OF THESE UNLESS YOU ARE CERTAIN YOU KNOW WHAT YOU ARE DOING!! */
    /*****************************************************************************************/
    /* THE ORDER IS IMPORTANT FOR  THE WEIRDNESS GOING ON WITH THE EDGE CASE ROUTING  ********/
    /*****************************************************************************************/
    //always available
    let publicPaths;
    let derivedRoutes = [];
    let blindRedirects = [];
    let nodeMap = [];
    if (!this.props.origin.loading) {
      for (const key in this.props.origin.nodeMap) {
        //set Temp to BlindRedirects[index] and its index

        let temp = this.props.origin.nodeMap[key];
        // //take the empty array derivedRoutes = [] and push a Route into it
        // //  set the path to /:language/blindRedirects[indexOfBlindRedirects].source_url   example /apple-touch-icon-precomposed.png

        if (temp.content_type == "blind-redirects") {
          if (temp.destination_url.value.includes("http")) {
            let Site = temp.destination_url.value;

            nodeMap.push(
                <Route
                    exact
                    key={`derived_blind${temp.nid}`}
                    path={`${temp.source_url.value}`}
                    render={() => window.location.replace(Site)}
                />
            );
          } else {
            nodeMap.push(
                <Route
                    exact
                    key={`derived_blind${temp.nid}`}
                    path={`${temp.source_url.value}`}
                    component={() => (
                        <Redirect to={`${temp.destination_url.value}`} />
                    )}
                />
            );
          }
        } else
          nodeMap.push(
              <Route key="node" path="/node/:nid/" component={Node} />
          );
      }

      for (const key in this.props.origin.blindRedirects) {
        //set Temp to BlindRedirects[index] and its index
        let temp = this.props.origin.blindRedirects[key];
        blindRedirects.push(
            <Route
                // exact={temp.nid == 12237}
                exact
                key={`derived_blind${temp.nid}`}
                path={`/${temp.source_url.value}`}
                component={() => <Redirect to={`/${temp.destination_url.value}`} />}
            />
        );
      }

      try {
        for (const key in this.props.origin.landingPages) {
          let lp = this.props.origin.landingPages[key];
          if (lp.urlFix) {
            let url = lp.landing_page_url.value;
            nodeMap.push(
                <Route
                    key={`derived${lp.nid}`}
                    path={`/${url}/:nid?`}
                    component={() => <Article props={{ nodeId: lp.nid }} />}
                />
            );
            nodeMap.push(
                <Route key={url} exact path={`/${url}`} component={Article} />
            );
          } else {
            let url = lp.landing_page_url.value;
            nodeMap.push(
                <Route key={url} exact path={`/${url}`} component={Article} />
            );
          }
        }
      } catch (e) {
        console.log("caught the thing", $e);
      }

      let manualRoutes = [
        <Route
            key={"manual-search"}
            path={"/manuals-search/:query?"}
            component={Manuals}
        />,
      ];
      let productRoutes = [
        <Route
            key={"productLandPride"}
            path={`/products/:nid?/:title?`}
            component={LandPrideProduct}
        />,
        <Route
            key={"productLandPride"}
            path={`/products/:nid?/:title?`}
            component={LandPrideProduct}
        />,
        <Route
            key="products=home"
            path="/product-search/:family?/:productline?"
            component={LandprideProducts}
        />,
        <Route
            key="products=home"
            path="/product-search/:family?/:productline?"
        >
          <LandprideProducts />
        </Route>,
      ];
      publicPaths = [
        ...nodeMap,
        ...blindRedirects,
        <Route
            key="dealerSearch"
            path="/dealer-search/:zip?/:city?/:state?/:filter?"
            component={DealerSearch}
        />,
        <Route
            key="dealerSearch"
            path="/dealer-search-results/:zip?/:city?/:state?/:filter?"
            component={DealerSearch}
        />,
        <Route
            key="faq"
            path="/service-faqs/all-categories/"
            component={FAQ}
        />,
        <Route
            key="faq"
            path="/service-faqs/all-categories/490"
            component={FAQ}
        />,
        <Route key="faq" path="/node/490" component={FAQ} />,
        <Route
            key="faq"
            path="/490/frequently-asked-questions"
            component={FAQ}
        />,
        <Route key="faq" path="/service-faqs/:nid?" component={Article} />,

        <Route key="index-no-lang" path={`/`} exact component={Home} />,
        <Route
            key="company-Websites"
            exact
            path="/company-websites"
            component={CompanyWebsites}
        />,
        <Route
            key="part-smart"
            exact
            path="/partsmart"
            component={Partsmart}
        />,
        <Route key="search" path="/search/:query?" component={Search} />,
        <Route
            key={"SERIAL NUMBER SEARCH"}
            path={`/serial-number-search/:number?`}
            component={SerialNumberSearch}
        />,
        ...derivedRoutes,
        ...manualRoutes,
        ...productRoutes,
        <Route key={"article"} path={`/:nid/:title`} component={Article} />,
        <Route
            key={"article-from-node"}
            path={`/:nid/:title?`}
            component={Node}
        />,

        <Route key="notFound" component={Node} />,
      ];
    }

    //these are here for if/when users can log in through the site. could use free firebase auth plan with the company gsuite so handle logins very  easy.
    //these are only available when logged in.
    let protectedPaths = [
      <Route key="Logout" path={`/:language?/Logout`} component={Logout} />,
      <Route key="Login" path={`/users`} component={Logout} />,
      <Route key="Login" path={`/login`} component={Logout} />,
      <Route key="Login" path={`/user`} component={Logout} />,
    ];

    //only available when not logged in. We dont want to show login if you are logged in
    let unprotectedPaths = [
      <Route key="Login" path={`/:language?/users`} component={Login} />,
      <Route key="Login" path={`/login`} component={Login} />,
      <Route key="Login" path={`/user`} component={Login} />,
      <Route key="Logout" path={`/Logout`} component={Login} />,
    ];

    // Check if the token is expired
    let today = new Date();
    let expire = new Date(sessionStorage.getItem("expirationDate"));
    let booleanTest = today < expire;

    let allowedPaths = [].concat(publicPaths);
    if (this.props.origin.VPN || process.env.NODE_ENV == "development") {
      allowedPaths = (sessionStorage.getItem("token") && booleanTest
              ? protectedPaths
              : unprotectedPaths
      ).concat(publicPaths);
    }

    let paths = allowedPaths.map((element) => element);

    let myRouter = this.props.origin.loading ? (
        <Spinner />
    ) : (
        <Layout>
          <Switch>
            {paths}
          </Switch>
        </Layout>
    );
    //Handle click is setting the cookie when the div is clicked
    return myRouter;
  }
}
const mapStateToProps = (state) => {
  return {
    origin: { ...state.origin },
    upcomingShows: state.upcomingShows,
    auth: { ...state.auth },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchNav: () => dispatch(actions.fetchNav()),
    onFetchOrigin: () => dispatch(actions.fetchOrigin()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
