import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    testimonial: null,
    loading: true,
};

const fetchTestimonialStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
};

const fetchTestimonialSuccess = (state, action) => {
    return updateObject(state, {
        testimonial: action.data.testimonial,
        loading: false,
        error: false,
    });
};

const fetchTestimonialFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TESTIMONIAL_START:
            return fetchTestimonialStart(state, action);
        case actionTypes.FETCH_TESTIMONIAL_SUCCESS:
            return fetchTestimonialSuccess(state, action);
        case actionTypes.FETCH_TESTIMONIAL_FAIL:
            return fetchTestimonialFail(state, action);

        default:
            return state;
    }
};

export default reducer;
