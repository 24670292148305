import React from "react";
import Link from "react-router-dom/Link";
import classes from "./HomeBanner.module.scss";
import Parser from "html-react-parser";
import ConditionalWrapper from "../../../hoc/ConditionalWrapper/ConditionalWrapper";

var productImageAVIF = "https://cdn-assets.greatplainsmfg.com/shared_assets/landpride/images/products/LP22_RTA2570_Render1.avif"
var productImagePng = "https://cdn-assets.greatplainsmfg.com/shared_assets/landpride/images/products/LP22_RTA2570_Render1.png"
var productImageWebp = "https://cdn-assets.greatplainsmfg.com/shared_assets/landpride/images/products/LP22_RTA2570_Render1.webp"

function HomeBanner(props) {
  return (
      <ConditionalWrapper
          condition={props.auth}
          wrapper={(children) => (
              <div className={"HoverOver"}>
                  <a
                      className={"EditThisLink"}
                      target="_blank"
                      href={`https://cms.landpride.com/node/9/edit`}
                  >
                      Edit This Content
                  </a>
                  {children}
              </div>
          )}
      >
      <div className={`${classes.Wrapper} testGlobalClass`}>
      <div className={classes.Card}>
        {/* inherited style */}
        {/*<h3>{props.data != undefined  && props.data.title != undefined ? Parser(props.data.title) : null}</h3>*/}

          <h3>OVER <span>500 PRODUCTS</span> TO FIT YOUR LIFESTYLE</h3>

          {props.data != undefined  && props.data.body != undefined && props.data.body.summary != undefined ? Parser(props.data.body.summary) : null}
          {/*Salina, Kansas-based Land Pride is a company proud of our Midwestern roots. Land Pride, a Division of Great Plains Mfg., Inc., has been a leader in grounds maintenance equipment since our inception in 1986. Today, we are known not only as a leader in turf equipment, but also recognized across North America as a leader in tractor-mounted implements and Kubota-branded CE (construction) attachments that include Rotary Tillers, Grooming Mowers, Seeders, Rotary Cutters, Rear Blades, Snow Blowers, Grapples, Buckets, Concrete Claws, Hopper Brooms, Hydraulic Breakers, and much more.*/}


        {/* This span is here because the arrow was floating right as a background on the element because of line breaks.. */}
        <Link className={classes.Link} to="/node/9">
          Learn More<span></span>
        </Link>
      </div>

      <div className={classes.Background}>

        <picture>
        {/*  /!*Use AVIF iamge if the browser supports it*!/*/}
          <source
              srcSet={productImageAVIF}
              type="image/avif"
          />
          <source
              srcSet={productImageWebp}
              type="image/avif"
          />
        {/*  /!*Fall back if the browser does not support avig*!/*/}
          <img
              className={classes.Image}
              src={productImagePng}
              alt={"BD7600 Box Drill"}
              loading="lazy"
          />
        </picture>


      </div>
    </div>
      </ConditionalWrapper>
  );
}
export default React.memo(HomeBanner)
