import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";
import {FETCH_TESTIMONIAL_FAIL, FETCH_TESTIMONIAL_START, FETCH_TESTIMONIAL_SUCCESS} from "./actionTypes";

export const fetchTestimonial = (familyPath) => {
    return (dispatch) => {
        dispatch(fetchTestimonialStart());
        axios
        .get("/testimonial")
        .then((response) => {
            dispatch(fetchTestimonialSuccess(response.data));
        })
        .catch((err) => {});
    };
};

/***********************************************************
 // Fetch All Products
 ***********************************************************/
export const fetchTestimonialStart = () => {
    return {
        type: actionTypes.FETCH_TESTIMONIAL_START,
    };
};

export const fetchTestimonialSuccess = (data) => {
    return {
        data: data,
        type: actionTypes.FETCH_TESTIMONIAL_SUCCESS,
    };
};

export const fetchTestimonialFail = (error) => {
    return {
        type: actionTypes.FETCH_TESTIMONIAL_FAIL,
        error: error,
    };
};
