import React, { Fragment, useState } from "react";
import Link from "react-router-dom/Link";
import { uuidv4 } from "./../../shared/utility";
import classes from "./LinkSwitch.module.scss";

export default function LinkSwitch(props) {
  const [search, setSearch] = useState("");
  let linkClass = null;
  if (props.linkClass != null) {
    linkClass = props.linkClass;
  }
  const onChange = (event) => {
    setSearch(event.target.value);
  };

  const updateInput = (e) => {
    setSearch("");
  };

  let key = uuidv4();

  {
    if (props.link[0] === "/" && props.link !== "/search") {
      return (
        <Fragment key={key}>
          <Link className={`${linkClass} Link`} to={`${props.link}`} >
            {props.text}
          </Link>
        </Fragment>
      );
    }
    if (props.text == "Search") {
      return (
        <div
          id={`HeaderSearchBlock`}
          className={`${classes.HeaderSearchBlock} HeaderSearchBlock`}
        >
          <form className={`${classes.SearchForm} SearchForm`}>
            <label
              className={`${classes.SearchLabel} SearchLabel`}
              htmlFor={"Search"}
            >
              Search
            </label>
            <input
              className={`${classes.Search} Search`}
              type="text"
              name="Search"
              placeholder="Search"
              value={search}
              onChange={onChange}
              maxLength="256"
            />

            <Link
              className={`${classes.ButtonAnchor} Link`}
              to={`${props.link}/${search}`}
            >
              <input
                name="serach"
                onClick={updateInput}
                type="submit"
                value="Search"
                className={`${classes.SubmitButton} SubmitButton`}
                placeholder=""
              />
            </Link>
          </form>
        </div>
      );
    }else
      return (
        <Fragment key={key}>
          {props.text != "Customer Survey" ? <a className={linkClass} target="_blank" href={props.link}>
            {props.text}
          </a>
          :
          <a className={linkClass} href={props.link}>
            {props.text}
          </a>}
        </Fragment>
      );
  }
}
