import React, { Component, Fragment } from "react";
import Link from "react-router-dom/Link";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";
import classes from "./ProductCarousel.module.scss";
import "pure-react-carousel/dist/react-carousel.es.css";
import ConditionalWrapper from "../../hoc/ConditionalWrapper/ConditionalWrapper";

export default class ProductCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  //
  componentWillUnmount() {
    clearTimeout();
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  //
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    setTimeout(() => {}, 400);
  }



  render(props) {

    let slides = this.props.products
      .map((current, index) => {
        let temp =  current.family_name.value != "CE Attachments" ? current.family_name.value.toString().toLowerCase().replace(" ", "-") : "kubota-ce-attachments"
        return temp != "precision-application" ? (

          <Slide
            key={current.nid}
            index={index}
            aria-label={current.family_name.value}
            role="presentation"
          >
            <ConditionalWrapper
                condition={this.props.auth}
                wrapper={(children) => (
                    <div className={"HoverOver"}>
                      <a
                          className={"EditThisLink"}
                          target="_blank"
                          href={`https://cms.landpride.com/node/${current.nid}/edit`}
                      >
                        Edit This Content
                      </a>
                      {children}
                    </div>
                )}
            >

            <Link
              className={"Link"}
              to={`/product-search/${temp}`}
              aria-label={current.family_name.value}
              role="presentation"
            >
              <div
                className={`${classes.Slide} Slide`}
                aria-label={current.family_name.value}
                role="presentation"
              >
                <div className={`${classes.Slide2} Slide2`} role="presentation">
                  <div
                    className={`${classes.Slide3} Slide3`}
                    role="presentation"
                  >
                    {/* <Image src={process.env.REACT_APP_IMAGE_URL + current.field_product_studio_image} /> */}
                    {this.state.width > 768 ? (
                      <Image
                        role="img"
                        src={current.studio_image.values[0].images["300x300_uri"]}
                        className={`${classes.Image} Image`}
                        aria-label={current.family_name.value}
                        loading={"lazy"}
                      />
                    ) : (
                      <Image
                        role="img"
                        src={current.studio_image.values[0].images["300x300_uri"]}
                        className={`${classes.Image} Image`}
                        aria-label={current.family_name.value}
                        loading={"lazy"}
                      />
                    )}
                  </div>
                  <p className={`${classes.FamilyName} FamilyName`}>
                    {current.family_name.value}
                  </p>
                </div>
              </div>
            </Link>
            </ConditionalWrapper>
          </Slide>
        ) : null;
      });

    let slideCount = this.props.products.length;

    let visibleSlides = 3;
    if (this.state.width >= 400) visibleSlides = 4;
    if (this.state.width >= 768) visibleSlides = 5;
    if (this.state.width >= 1000) visibleSlides = 5;
    if (this.state.width >= 1450) visibleSlides = 5;
    if (this.state.width >= 1700) visibleSlides = 6;

    let naturalSlideHeight = 360;
    if (this.state.width <= 400) naturalSlideHeight = 600;
    if (this.state.width <= 564) naturalSlideHeight = 600;
    if (this.state.width <= 768) naturalSlideHeight = 500;
    if (this.state.width >= 769) naturalSlideHeight = 380;

    let step = visibleSlides - 1 ? visibleSlides - 1 : visibleSlides;

    return (
      <Fragment>
        <CarouselProvider
          aria-label="Products"
          role="marquee"
          className={classes.Carousel}
          visibleSlides={visibleSlides}
          totalSlides={slideCount}
          step={step}
          naturalSlideWidth={400}
          naturalSlideHeight={naturalSlideHeight}
          hasMasterSpinner
          infinite="true"
          touchEnabled={true}
          lockOnWindowScroll={true}
        >
          <Slider role="marquee">{slides}</Slider>
          <div className={`${classes.ButtonDiv} ButtonDiv`}>
            <ButtonBack
              className={`${classes.BackButton} BackButton`}
              role="button"
            >
              &#10094; Previous
            </ButtonBack>

            <ButtonNext
              className={`${classes.NextButton} NextButton`}
              role="button"
            >
              Next &#10095;
            </ButtonNext>
          </div>
        </CarouselProvider>
      </Fragment>
    );
  }
}
