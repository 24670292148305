import React from "react";
import classes from "./Logo.module.scss";
import { NavLink } from "react-router-dom";

let LogoClass = null;

const switcher = (type) => {
  switch (type) {
    case "header":
      return classes.Header;
    case "footer":
      return classes.Footer;
    default:
      return null;
  }
};

 function Logo(props) {
  LogoClass = switcher(props.logoType);
  return (
    <div onClick={props.closed} className={classes.Logo}>
      <NavLink to="/">
        <div className={`${classes.ImageDiv} ${LogoClass}`}>
          {props.logoType == "footer" ? (
            <img
              className={classes.Image}
              src="https://cdn-assets.greatplainsmfg.com/shared_assets/landpride/images/products/LPlogo_gold.png"
              alt="Land Pride Logo"
            />
          ) : (
              <img
                  className={classes.Image}
                  src="https://cdn-assets.greatplainsmfg.com/shared_assets/landpride/images/products/LPlogo_gold.png"
                  alt="Land Pride Logo"
              />
          )}
          <span className={classes.Text}>Home</span>
        </div>
      </NavLink>
    </div>
  );
}

export default React.memo(Logo)
