import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchArticles = () => {
  return (dispatch) => {
    dispatch(fetchArticlesStart());
    axios
      .get("/articles")
      .then((response) => {
        // console.log("got the response", response);
        //
        // console.log("got the response data", response.data);
        // console.log("got the response data sections", response.data.sections);
        dispatch(fetchArticlesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchArticlesFail(err));
      });
  };
};

export const fetchBanners = () => {
  return (dispatch) => {
    dispatch(fetchBannersStart());
    axios
      .get("/banners")
      .then((response) => {
        dispatch(fetchBannersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchBannersFail(err));
      });
  };
};

/***********************************************************
// Fetch All Manuals
***********************************************************/
export const fetchArticlesStart = () => {
  return {
    type: actionTypes.FETCH_ARTICLES_START,
  };
};

export const fetchArticlesSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_ARTICLES_SUCCESS,
  };
};

export const fetchArticlesFail = (error) => {
  return {
    type: actionTypes.FETCH_ARTICLES_FAIL,
    error: error,
  };
};

export const fetchBannersStart = () => {
  return {
    type: actionTypes.FETCH_BANNERS_START,
  };
};

export const fetchBannersSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_BANNERS_SUCCESS,
  };
};

export const fetchBannersFail = (error) => {
  return {
    type: actionTypes.FETCH_BANNERS_FAIL,
    error: error,
  };
};
