import React, { useEffect, useState } from "react";
import classes from "./Square.module.scss";
import Link from "react-router-dom/Link";
import shaders from "./../../../shared/scss/variables/shading";
import Parser from "html-react-parser";
import ConditionalWrapper from "../../../hoc/ConditionalWrapper/ConditionalWrapper";

export default function Square(props) {
  const mediaMatch = window.matchMedia("(max-width: 768px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  var imageUrl = props.square.background_image.values[0].images["2600px_uri"]

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  if (matches) {
    imageUrl = props.square.background_image.values[0].images["1300px_uri"]+`?nocache=${props.square.updated_timestamp_unix}`
  }

  let classList = [classes.Wrapper];
  //setting background style information here because I need to pass variable to it
  let shades = shaders();
  let shading = shades[props.square.background_shading.values[0].name];

  let style = {
    backgroundImage: `linear-gradient(${shading}, ${shading} ), url(${imageUrl}?nocache=${props.square.updated_timestamp_unix})`,
    backgroundPosition: `center left`,
    // backgroundPosition: `${props.square.["background_ valign"].value} ${props.square.["background_ halign"].value}`,
    // backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
        // |fixed|local|initial|inherit;

  };


  // adds classes to the square based on their position in the array
  if (props.full == true) {
    classList.push(classes.Full);
    classList.push(props.square.content_location == "left" && classes.FullLeft);
    classList.push(
      props.square.content_location == "right" && classes.FullRight
    );
    classList.push(
      props.square.content_location == "center" && classes.FullCenter
    );
  } else {
    classList.push(classes.Small);
    classList.push(props.side == "left" ? classes.Left : classes.Right);
  }

  let classString = "";
  classList.forEach((element) => {
    classString += element + " ";
  });


  return (
      <div className={`${classString} String`} style={style}>
      <div className={`${classes.Content} Content`}>
        <ConditionalWrapper
          condition={props.auth}
          wrapper={(children) => (
              <div className={"HoverOver"}>
                <a
                    className={"EditThisLink"}
                    target="_blank"
                    href={`https://cms.landpride.com/node/${props.square.nid}/edit`}
                >
                  Edit This Content
                </a>
                {children}
              </div>
          )}
      >
        <div>
          {props.square.icon != null && props.square.icon.length != 0 ? (
            <img
              className={`${classes.Icon} Icon`}
              src={props.square.icon.values[0].url+`?nocache=${props.square.updated_timestamp_unix}`}
              alt={`${Parser(props.square.title)} Icon`}
              loading={"lazy"}
            />
          ) : null}
        </div>

        <div className={`${classes.Title} Title`}>
         <h3>{Parser(props.square.square_title.value)}</h3>
        </div>
        <div className={`${classes.Subtitle} Subtitle`}>
          {Parser(props.square.caption.value)}
        </div>


        {!props.square.read_more_link.value.includes("http") ? (
          <Link
            className={`${classes.Button} Button`}
            to={props.square.read_more_link.value}
          >
            <button  className={`${classes.Button} Button`}>Learn More</button>
          </Link>
        ) : (
          <a
            className={`${classes.Button} Button`}
            target="_blank"
            href={`${props.square.read_more_link.value}`}
          >
            <button  className={`${classes.Button} Button`}>Learn More</button>
          </a>
        )}
        </ConditionalWrapper> </div>
    </div>

  );
}

