import * as actionTypes from "./actionTypes"
import axios from "./../../axios-api";

export const fetchLandProducts = (familyPath) => {
    return (dispatch) => {
        dispatch(fetchLandProductsStart());
        axios.get("/products/")
            .then((response) => {
                if(familyPath == undefined){
                    familyPath = "all-products";
                }
                dispatch(fetchLandProductsSuccess(response.data, familyPath))
        })
            .catch((errr) => {})
    }
}


export const fetchLandProduct = (nId) => {
    return (dispatch) => {
        dispatch(fetchLandProductStart());
        axios
        .get(`/products/${nId}`)
        .then((response) => {
            dispatch(fetchLandProductSuccess(response.data));
        })
        .catch((err) => {});
    };
};
/***********************************************************
 // Filter Options
 ***********************************************************/
export const filterLandChange = (parent, child) => {
    return {
        parent: parent,
        child: child,
        type: actionTypes.FILTER_LAND_CHANGE,
    };
};

export const toggleShowChildrenLandPride = (section) => {
    return {
        section: section,
        type: actionTypes.TOGGLE_LAND_SHOW_CHILDREN,
    };
};

export const searchStringUpdate = (string) => {
    return {
        type: actionTypes.SEARCH_LAND_STRING_UPDATE,
        string: string,
    };
};
export const resetFlagsLandPride = () => {
    return {
        type: actionTypes.RESET_FLAGS_LANDPRIDE,
    };
};

/***********************************************************
 // Fetch All Products
 ***********************************************************/
export const fetchLandProductsStart = () => {
    return {
        type: actionTypes.FETCH_LAND_PRODUCTS_START,
    };
};

export const fetchLandProductsSkipped = () => {
    return {
        type: actionTypes.FETCH_LAND_PRODUCTS_SKIPPED,
    };
};

export const fetchLandProductsSuccess = (data, familyPath) => {

    return {
        data: data,
        familyPath: familyPath,
        type: actionTypes.FETCH_LAND_PRODUCTS_SUCCESS,
    };
};

export const fetchLandProductsFail = (error) => {
    return {
        type: actionTypes.FETCH_LAND_PRODUCTS_FAIL,
        error: error,
    };
};
/***********************************************************
 // Fetch single Products
 ***********************************************************/
export const fetchLandProductStart = () => {
    return {
        type: actionTypes.FETCH_LAND_PRODUCT_START,
    };
};

export const fetchLandProductSuccess = (data) => {
    return {
        data: data,
        type: actionTypes.FETCH_LAND_PRODUCT_SUCCESS,
    };
};

export const fetchLandProductFail = (error) => {
    return {
        type: actionTypes.FETCH_LAND_PRODUCT_FAIL,
        error: error,
    };
};
