import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import React, { Fragment, PureComponent} from "react";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "axios";
import Spinner from "../../components/UI/Spinner/Spinner";
import Squares from "../../components/Squares/Squares";
import Banners from "./../../components/Banners/Banners";
import HomeBanner from "./HomeBanner/HomeBanner";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";
import ProductCarousel from "../../components/ProductCarousel/ProductCarousel";
import TestimonialPreview from "../../components/TestimonialPreview/TestimonialPreview";
import DealerSearchBlock from "./HomeBlocks/dealerSearchBlock";
import { Helmet } from "react-helmet";


class Home extends PureComponent {
  componentDidMount() {
    this.props.onFetchHome();
    this.props.onFetchArticles("9");
  }


  render() {
    //will render a loading icon if the data isn't loaded in yet
    let content = <Spinner />;
    let banners,
      squares,
      homeBanner,
      productCarousel,
        dealerSearchBlock,
      testimonialPreview = null;

    if (!this.props.loading) {
      if (!this.props.error) {
        try {
          banners = (
            <Banners
              banners={this.props.banners}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              useKeyboardArrows={true}
              autoPlay={true}
              stopOnHover={true}
              interval={7000}
              transitionTime={350}
              auth={this.props.auth.auth}
            />
          );

          productCarousel = (
            <ProductCarousel
              products={this.props.carousel}
              origin={this.props.origin}
              auth={this.props.auth.auth}
            />
          );
          homeBanner = this.props.articles.articles ? <HomeBanner data={this.props.articles.articles}   auth={this.props.auth.auth} /> : null

          squares = <Squares squares={[this.props.squares]}   auth={this.props.auth.auth} />;

          dealerSearchBlock = (< DealerSearchBlock />)

          testimonialPreview = (
            <TestimonialPreview testimonial={this.props.testimonial}   auth={this.props.auth.auth} />
          );

          content = (
            <Fragment>
              {banners}
              {productCarousel}
              {homeBanner}
              {squares}
                {dealerSearchBlock}

              {/*<Suspense fallback={<div>Loading...</div>{squares}></Suspense>*/}
              {testimonialPreview}
            </Fragment>
          );
        } catch (err) {
          content = <ErrorDisplay />;
        }
      } else {
        content = <ErrorDisplay />;
      }
    }

    return (
      <Fragment>
        <Helmet>
          <title>Home | Land Pride</title>
          <meta
            name="description"
            content="Land Pride, a Division of Great Plains Mfg., Inc., is a leader in tractor-mounted implements and Kubota-branded CE (construction) attachments including Tillers, Mowers, Seeders, Cutters, Rear Blades, Snow Blowers, Grapples, Buckets, and much more. "
          />
        </Helmet>
        {content}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.home,
    origin: state.origin,
    articles: { ...state.articles },
    auth: { ...state.auth },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchHome: () => dispatch(actions.fetchHome()),
    onFetchArticles: (nid) => dispatch(actions.fetchSingleArticle(nid)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Home, axios));
