import "core-js/stable";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "./polyfills.js";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import "./index.scss";
import App from "./App";
import navReducer from "./store/reducers/nav";
import originReducer from "./store/reducers/origin";
import homeReducer from "./store/reducers/home";
import manualsReducer from "./store/reducers/manuals";
import upcomingShowsReducer from "./store/reducers/upcomingShows";
import articlesReducer from "./store/reducers/articles";
import bannersReducer from "./store/reducers/banners";
import searchReducer from "./store/reducers/search";
import serialReducer from "./store/reducers/serial";
import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";
import BrowserChecker from "./hoc/BrowserCheck/BrowserChecker";
import landProductsReducer from "./store/reducers/landpride-products";
import authReducer from "./store/reducers/auth";
import faqsReducer from "./store/reducers/faq";
import testimonialReducer from "./store/reducers/testimonial";
import adslickReducer from "./store/reducers/AdSlick";
import partsmartReducer from "./store/reducers/partsmart"

//disable warning errors and messages
// console.error = () => {};

console.warn = () => {};
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;
// const composeEnhancers = compose;

const rootReducer = combineReducers({
  home: homeReducer,
  nav: navReducer,
  origin: originReducer,
  articles: articlesReducer,
  banners: bannersReducer,
  manuals: manualsReducer,
  upcomingShows: upcomingShowsReducer,
  search: searchReducer,
  serial: serialReducer,
  landProducts: landProductsReducer,
  faqs: faqsReducer,
  auth: authReducer,
  testimonial: testimonialReducer,
  adSlick: adslickReducer,
  partsmart: partsmartReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserChecker debug={false} />
    <BrowserRouter>
      <Fragment>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Fragment>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
// registerServiceWorker();
