import * as actionTypes from "./actionTypes";
// import axios from 'axios'
import YAML from "js-yaml";
import YamlPath from "../../config/yaml/nav.yml";

export const fetchNavStart = () => {
  return {
    type: actionTypes.NAV,
  };
};

export const fetchNavSuccess = (nav) => {
  return {
    type: actionTypes.FETCH_NAV_SUCCESS,
    nav: nav,
  };
};

export const fetchNavFail = (error) => {
  return {
    type: actionTypes.FETCH_NAV_FAIL,
    error: error,
  };
};

export const fetchNav = () => {
  return (dispatch) => {
    dispatch(fetchNavStart());
    fetch(YamlPath)
      .then((r) => r.text())
      .then((text) => {
        var doc = YAML.load(text);
        dispatch(fetchNavSuccess(doc));
      })
      .catch((err) => {
        dispatch(fetchNavFail(err));
      });
  };
};
