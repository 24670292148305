import React from "react";
import Link from "react-router-dom/Link";
import classes from "./TestimonialPreview.module.scss";
import Parser from "html-react-parser";
import Qoutes from "./../../assets/images/Icons/quotes-38px.png"
import Truncate from "../../functions/truncate";
import ConditionalWrapper from "../../hoc/ConditionalWrapper/ConditionalWrapper";

 function TestimonialPreview(props) {

    let Testimonials = [...props.testimonial]

  let randomTest = Math.floor(
    Math.random() * Math.floor(Testimonials.length)
  );
    let Message = " "
    if(Testimonials[randomTest].body != undefined && Testimonials[randomTest].body.value.length  ){
         Message = Testimonials[randomTest].body.summary ?  Testimonials[randomTest].body.summary : Testimonials[randomTest].body.value
    }

   // Truncate(Testimonials[28].body.value, 600)

  return (
    <div className={classes.Wrapper}>
        <ConditionalWrapper
            condition={props.auth}
            wrapper={(children) => (
                <div className={"HoverOver"}>
                    <a
                        className={"EditThisLink"}
                        target="_blank"
                        href={`https://cms.landpride.com/node/${Testimonials[randomTest].nid}/edit`}
                    >
                        Edit This Content
                    </a>
                    {children}
                </div>
            )}
        >
        <img
        className={classes.Icon}
        src={Qoutes}
        alt="Qoutes"
        loading={"lazy"}
      />
      <h3>
        <Link className={classes.Header} to={`/262/testimonials`}>
          See what our customers are saying...
        </Link>
      </h3>
        <div className={classes.Testimonial}>{Parser(`${Truncate(Message,600)}`)}</div>
      <div className={classes.UserInfo}>
        {Testimonials[randomTest].customer_name != undefined ? Testimonials[randomTest].customer_name.value : null},{" "}
        {Testimonials[randomTest].location != undefined ? Testimonials[randomTest].location.value : null}{" "}
          --Topic: {Testimonials[randomTest].title != undefined ? Testimonials[randomTest].title : null}{" "}
      </div>
      <Link className={classes.Button} to={`/262/testimonials`}>
        <button>Read Others</button>
      </Link>
        </ConditionalWrapper>
    </div>
  );
}

export default React.memo(TestimonialPreview)
