/***********************************************************
 //Auth
 ***********************************************************/
export const FETCH_AUTH_START = "FETCH_AUTH_START";
export const FETCH_AUTH_SUCCESS = "FETCH_AUTH_SUCCESS";
export const FETCH_AUTH_FAIL = "FETCH_AUTH_FAIL";
export const FETCH_AUTH_LOGOUT = "FETCH_AUTH_LOGOUT";

/***********************************************************
 //PRODUCTS
 ***********************************************************/
export const FETCH_LAND_PRODUCTS_START = "FETCH_LAND_PRODUCTS_START";
export const FETCH_LAND_PRODUCTS_SUCCESS = "FETCH_LAND_PRODUCTS_SUCCESS";
export const FETCH_LAND_PRODUCTS_FAIL = "FETCH_LAND_PRODUCTS_FAIL";
export const FETCH_LAND_PRODUCTS_SKIPPED = "FETCH_LAND_PRODUCTS_SKIPPED";


//Single Nid
export const FETCH_LAND_PRODUCT_START = "FETCH_LAND_PRODUCT_START";
export const FETCH_LAND_PRODUCT_SUCCESS = "FETCH_LAND_PRODUCT_SUCCESS";
export const FETCH_LAND_PRODUCT_FAIL = "FETCH_LAND_PRODUCT_FAIL";

// export const FETCH_PRODUCTS_SKIPPED = "FETCH_PRODUCTS_SKIPPED";
export const TOGGLE_LAND_SHOW_CHILDREN = "TOGGLE_LAND_SHOW_CHILDREN";
export const SEARCH_LAND_STRING_UPDATE = "SEARCH_LAND_STRING_UPDATE";
export const FILTER_LAND_CHANGE = "FILTER_LAND_CHANGE";
export const RESET_FLAGS_LANDPRIDE = "RESET_FLAGS_LANDPRIDE";

//part smart
export const FETCH_PARTSMART_START = "FETCH_PARTSMART_START";
export const FETCH_PARTSMART_SUCCESS = "FETCH_PARTSMART_SUCCESS";
export const FETCH_PARTSMART_FAIL = "FETCH_PARTSMART_FAIL";
export const FETCH_PARTSMART_CLEAR = "FETCH_PARTSMART_CLEAR";

/***********************************************************
//NAV_START = 'FETCH_NAV_START';
***********************************************************/
export const FETCH_NAV_START = "FETCH_NAV_START";
export const FETCH_NAV_SUCCESS = "FETCH_NAV_SUCCESS";
export const FETCH_NAV_FAIL = "FETCH_NAV_FAIL";
export const NAV = "NAV";


/***********************************************************
//products
***********************************************************/
export const FETCH_HOME_START = "FETCH_HOME_START";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAIL = "FETCH_HOME_FAIL";

/***********************************************************
//products
***********************************************************/
export const FETCH_ORIGIN_START = "FETCH_ORIGIN_START";
export const FETCH_ORIGIN_SUCCESS = "FETCH_ORIGIN_SUCCESS";
export const FETCH_ORIGIN_FAIL = "FETCH_ORIGIN_FAIL";


/***********************************************************
 //FAQS
 ***********************************************************/
export const FETCH_FAQS_START = "FETCH_FAQS_START";
export const FETCH_FAQS_SUCCESS = "FETCH_FAQS_SUCCESS";
export const FETCH_FAQS_FAIL = "FETCH_FAQS_FAIL";

/***********************************************************
 //FAQS
 ***********************************************************/
export const FETCH_TESTIMONIAL_START = "FETCH_TESTIMONIAL_START";
export const FETCH_TESTIMONIAL_SUCCESS = "FETCH_TESTIMONIAL_SUCCESS";
export const FETCH_TESTIMONIAL_FAIL = "FETCH_TESTIMONIAL_FAIL";


/***********************************************************
//Ads
***********************************************************/
export const FETCH_ADS_START = "FETCH_ADS_START";
export const FETCH_ADS_SUCCESS = "FETCH_ADS_SUCCESS";
export const FETCH_ADS_FAIL = "FETCH_ADS_FAIL";


/***********************************************************
 //manuals
 ***********************************************************/
export const FETCH_MANUALS_START = "FETCH_MANUALS_START";
export const FETCH_MANUALS_SUCCESS = "FETCH_MANUALS_SUCCESS";
export const FETCH_MANUALS_FAIL = "FETCH_MANUALS_FAIL";
export const FETCH_MANUALS_CLEAR = "FETCH_MANUALS_CLEAR";


/***********************************************************
//upcoming shows
***********************************************************/
export const FETCH_UPCOMING_SHOWS_START = "FETCH_UPCOMING_SHOWS_START";
export const FETCH_UPCOMING_SHOWS_SUCCESS = "FETCH_UPCOMING_SHOWS_SUCCESS";
export const FETCH_UPCOMING_SHOWS_FAIL = "FETCH_UPCOMING_SHOWS_FAIL";
/***********************************************************


/***********************************************************
//articles
***********************************************************/
export const FETCH_ARTICLES_START = "FETCH_ARTICLES_START";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_SUCCESS_SINGLE = "FETCH_ARTICLES_SUCCESS_SINGLE";
export const FETCH_ARTICLES_FAIL = "FETCH_ARTICLES_FAIL";

/***********************************************************
//banners
***********************************************************/
export const FETCH_BANNERS_START = "FETCH_BANNERS_START";
export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";
export const FETCH_BANNERS_FAIL = "FETCH_BANNERS_FAIL";
/***********************************************************
 //search
 ***********************************************************/
export const FETCH_SEARCH_START = "FETCH_SEARCH_START";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const FETCH_SEARCH_FAIL = "FETCH_SEARCH_FAIL";
/***********************************************************


/***********************************************************
 //DEALER UPDATE
 ***********************************************************/
export const FETCH_DEALER_START = "FETCH_DEALER_START";
export const FETCH_DEALER_SUCCESS = "FETCH_DEALER_SUCCESS";
export const FETCH_DEALER_FAIL = "FETCH_DEALER_FAIL";

/***********************************************************
 //Serial search
 ***********************************************************/
export const FETCH_SERIAL_START = "FETCH_SERIAL_START";
export const FETCH_SERIAL_SUCCESS = "FETCH_SERIAL_SUCCESS";
export const FETCH_SERIAL_FAIL = "FETCH_SERIAL_FAIL";
export const FETCH_SERIAL_RESET = "FETCH_SERIAL_RESET";
