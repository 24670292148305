import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Panel from "./Panel/Panel";
import Parser from "html-react-parser";


 function Banner(props) {
  let banner = props.banners != undefined && props.banners["335"] != undefined && props.banners["335"] ? props.banners["335"] : props.banners
  let multipleSlides = banner != undefined && banner.length == 1 ? false : true;

  // function Start(data) {
  //   let date = new Date();
  //   let Today =
  //     (date.getMonth() > 8
  //       ? date.getMonth() + 1
  //       : "0" + (date.getMonth() + 1)) +
  //     "/" +
  //     (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
  //     "/" +
  //     date.getFullYear();
  //   if (data.start_date != undefined && data.start_date.length > 1) {
  //     if (data.start_date.split("/").join("") <= Today.split("/").join("")) {
  //       return data;
  //     } else if (
  //       data.start_date.split("/").join("") > Today.split("/").join("")
  //     ) {
  //       return null;
  //     }
  //   } else return data;
  // }
  //
  // function End(data) {
  //   let date = new Date();
  //   let Today =
  //     (date.getMonth() > 8
  //       ? date.getMonth() + 1
  //       : "0" + (date.getMonth() + 1)) +
  //     "/" +
  //     (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
  //     "/" +
  //     date.getFullYear();
  //
  //   if (data.expiration_date != undefined && data.expiration_date.length > 1) {
  //     if (
  //       data.expiration_date.split("/").join("") > Today.split("/").join("")
  //     ) {
  //       return data;
  //     } else if (
  //       data.expiration_date.split("/").join("") == Today.split("/").join("")
  //     ) {
  //       return null;
  //     } else if (
  //       data.expiration_date.split("/").join("") < Today.split("/").join("")
  //     ) {
  //       return null;
  //     }
  //   } else return data;
  // }


  return (
    <Carousel
      showIndicators={multipleSlides}
      swipeScrollTolerance={12}
      showThumbs={props.showThumbs}
      showStatus={props.showStatus}
      infiniteLoop={multipleSlides}
      useKeyboardArrows={props.useKeyboardArrows}
      autoPlay={props.autoPlay}
      stopOnHover={props.stopOnHover}
      swipeable={false}
      interval={props.interval}
      transitionTime={props.transitionTime}
    >
      {banner && banner
        // .filter(Start)
        // .filter(End)
        // .sort((a, b) => a.field_weight_value - b.field_weight_value)
        .map((current) => (

            current != undefined ? <Panel
            key={current.nid}
            data={{
                timeStamp: current.updated_timestamp_unix,
              isProduct: false,
              field_caption_position: current.caption_alignment.value ? current.caption_alignment.value : "right",
              field_vertical_alignment: current.vertical_alignment.value ?  current.vertical_alignment.value : "right",
              field_horizontal_alignment: current.horizontal_alignment ? current.horizontal_alignment : "right",
              field_banner_image: current.image.values[0].images != null && current.image.values[0].images["2600px_uri"] != undefined ? current.image.values[0].images["2600px_uri"] : null,
              field_read_more_link: current.read_more != undefined && current.read_more && current.read_more.url != undefined && current.read_more.url  ? current.read_more.url : null,
              button_label: current.read_more != undefined && current.read_more && current.read_more.label  ? current.read_more.label : null,
              banner_title: Parser(
                  current.banner_title.value
              ),
              field_caption_summary: Parser(
                  current.caption.value
              ),
            }}
            mobile={current.image.values[0].images}
            desktop={current.image.values[0].images}
            noSpan={true}
            auth={props.auth}
            nid={current != undefined && current.nid != undefined ? current.nid : "5076"}
          /> : null
        ))}
    </Carousel>
  );
}
export default React.memo(Banner)
