import React from "react";
import classes from "./ErrorDisplay.module.scss";
import errorLogo from "./../../assets/images/components/Error/error_icon.png";

function refreshPage() {
  window.location.reload();
}

 function Error() {
  return (
    <div className={classes.Wrapper}>
      <img src={errorLogo} alt={"Error Message Image"} style={{filter: "invert(62%) sepia(78%) saturate(459%) hue-rotate(342deg) brightness(96%) contrast(86%)"}}/>
      <h1>Something Isn't Right Here!</h1>
      <p>The Land Pride web developers have been made aware of this issue.</p>
      <p>Please try refreshing the page.</p>
      <div>
        <button type="button" onClick={refreshPage}>
          Reload
        </button>
      </div>
    </div>
  );
}
export default React.memo(Error)
