import * as actionTypes from "./../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  searchList: {},
  loading: true,
  searchString: "",
  searching: false,
};

const fetchSearchStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchSearchSuccess = (state, action) => {
  let searchList = [];
  action.data.forEach((v, k) => {
    searchList.push(v);
  });

  return updateObject(state, {
    searchList: searchList,
    loading: false,
    error: false,
  });
};

const fetchSearchFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_START:
      return fetchSearchStart(state, action);
    case actionTypes.FETCH_SEARCH_SUCCESS:
      return fetchSearchSuccess(state, action);
    case actionTypes.FETCH_SEARCH_FAIL:
      return fetchSearchFail(state, action);


    default:
      return state;
  }
};

export default reducer;
