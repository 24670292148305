import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchADS = (faq) => {
    return (dispatch) => {
        dispatch(fetchADSStart());
        axios
        .get("/ad-slicks/")
        .then((response) => {
            dispatch(fetchADSSuccess(response.data));
        })
        .catch((err) => {
            console.log(err)
        });
    };
};

/***********************************************************
 // Fetch All Products
 ***********************************************************/
export const fetchADSStart = () => {
    return {
        type: actionTypes.FETCH_ADS_START,
    };
};

export const fetchADSSuccess = (data) => {
    return {
        data: data,
        type: actionTypes.FETCH_ADS_SUCCESS,
    };
};


