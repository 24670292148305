import React, {Component} from 'react'


/*
// This class makes it so that the page scrolls to the top on a new page load.


//todo look into this
// Returing to a previous page using the back button will cause the page to be
// scrolled to where it was when leaving that page.
// !!!!!!!!!!!!!!!!!!!!!!!
//the else is in here because going from facilities to home to facilities would load
//facilties halfway down the page.. Weird effect, not critical, will look into.
//!!!!!!!!!!!!!!!!!!!!!!!!!*/
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {

    if(!window.location.pathname.includes("product-search/") && !window.location.pathname.includes("manuals-search")
        && !window.location.pathname.includes("service-faqs")&& !window.location.pathname.includes("galleries")){
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }else{
        window.scrollTo(0, 0)
      }
    }

  }
  render() {
    return this.props.children
  }
}
export default ScrollToTop
