import React from "react";
import Link from "react-router-dom/Link";

export default function LearnMoreButton(props) {
  return (
    <div className={`learnMoreWrapper`}>
      {props.buttonLink[0] == "/" ? (
        <Link to={props.buttonLink} className={"Link"}>
          <button className={"Button"}>Learn More</button>
        </Link>
      ) : (
        <a target="_blank" href={props.buttonLink} className={"Link"}>
          <button className={"Button"}>Learn More</button>
        </a>
      )}
    </div>
  );
}
