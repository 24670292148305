import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./NavigationItems.module.scss";
import LinkSwitch from "./../../../../LinkSwitch/LinkSwitch";

class navigationItems extends Component {
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      let current = this.props.nav[0].languages["en"].links;

      if (current == undefined || current == null) {
        current = this.props.nav[0].languages["en"].links;
      }

      navItems = current.map((item) => (
        <li key={item.link}>
          <LinkSwitch link={item.link} text={item.text} />
        </li>
      ));
    }
    return (
      <div>
        <ul className={`${classes.NavigationItems} NavigationItems`}>
          {navItems}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nav: state.nav.nav.navLinks,
    language: state.origin.language,
    loading: state.nav.loading,
  };
};

export default connect(mapStateToProps)(navigationItems);
