import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchFAQs = (faq) => {
    return (dispatch) => {
        dispatch(fetchFAQSStart());
        axios
        .get("/faq/")
        .then((response) => {
            dispatch(fetchFAQSSuccess(response.data));
        })
        .catch((err) => {});
    };
};

/***********************************************************
 // Fetch All Products
 ***********************************************************/
export const fetchFAQSStart = () => {
    return {
        type: actionTypes.FETCH_FAQS_START,
    };
};

export const fetchFAQSSuccess = (data) => {
    return {
        data: data,
        type: actionTypes.FETCH_FAQS_SUCCESS,
    };
};

export const fetchFAQSFail = (error) => {
    return {
        type: actionTypes.FETCH_FAQS_FAIL,
        error: error,
    };
};

